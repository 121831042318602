import styled from 'styled-components';

import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';

import Search from '../../../molecules/Inputs/Search/Search';

export const SearchBarContainer = styled.div`
  width: 100%;

  ${atMinWidth.md`
    margin-bottom: 96px;
  `}

  ${atMinWidth.lg`
    margin-bottom: 0px;
  `}

  ${Search} {
    border: 1px solid ${colors.gray[300]};
  }
`;
